import { Box, Link as MUILink, SxProps } from '@mui/material';
import { AppConfig } from '@portals/core-immobilien/src/config';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';

export type EstateLinkProps = {
  id: string;
  isLinkEnabled?: boolean;
  isEstateAd?: boolean;
  state?: string;
  children: React.ReactNode;
};

const style = (cursor?: boolean | undefined): SxProps => ({
  display: 'block',
  height: '100%',
  underline: 'none',
  color: 'inherit',
  cursor: cursor ? 'pointer' : 'default',
});

export const EstateLink: React.FC<EstateLinkProps> = React.forwardRef(
  (
    { id, isLinkEnabled = true, isEstateAd, state, children }: EstateLinkProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const { estateDetailsPath } = AppConfig;
    const router = useRouter();
    const url = `${estateDetailsPath}${encodeURIComponent(id)}.html`;
    const isPublished = isEstateAd && state === 'published';

    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        if (!isEstateAd && isLinkEnabled) {
          router.push(url);
        } else if (isLinkEnabled || isPublished) {
          window.open(url, '_ blank');
        }
      },
      [isEstateAd, isLinkEnabled, isPublished, router, url]
    );

    const estateLink = isLinkEnabled || isPublished ? url : undefined;

    return (
      <Box data-testid="estate-link" ref={ref} sx={{ height: '100%' }}>
        <MUILink href={estateLink} onClick={handleClick} sx={style(isLinkEnabled || isPublished)}>
          {children}
        </MUILink>
      </Box>
    );
  }
);

EstateLink.displayName = 'EstateLink';
