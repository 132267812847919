import { Box, Paper, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Button } from '@portals/core/src/components/Button/Button';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { toSelectOptions } from '@portals/core-immobilien/src/utils';
import { SelectInput } from '@portals/forms/src/components/SelectInput/SelectInput';
import { Form, Formik } from 'formik';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useCallback, useState } from 'react';

import { EstateAdFlowPath, getAllEstateAdFlowPaths } from '../../config/types/estateAdFlowPath';
import { getStyles } from './EstateAdFlowSelector.styles';

export const EstateAdFlowSelector = (): React.ReactElement => {
  const { t } = useTranslation();
  const router = useRouter();
  const theme = useTheme();
  const defaultValues = { estateAdFlowPath: EstateAdFlowPath.HOUSE };
  const [initialValues] = useState(defaultValues);

  const estateAdFlowPaths = getAllEstateAdFlowPaths(t);

  const styles = getStyles(theme);

  const handleSubmit = useCallback(
    (values) => {
      const path = values.estateAdFlowPath;
      router.push(path);
    },
    [router]
  );

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <Form>
        <Paper sx={styles.paper}>
          <Box sx={styles.box}>
            <Grid container spacing={2} rowSpacing={4}>
              <Grid size={{ xs: 12, md: 4 }} sx={styles.labelContainer}>
                <Typography variant="h5" sx={styles.label}>
                  {t('forms.estateAd.flowSelector.label')}
                </Typography>
              </Grid>
              <Grid size={{ xs: 12, md: 4 }}>
                <Box sx={styles.selectInputContainer}>
                  <SelectInput
                    variant="standard"
                    id="estateAdFlowPathSelect"
                    data-testid="estateAdFlowPathSelect"
                    name="estateAdFlowPath"
                    selectOptions={estateAdFlowPaths.map(toSelectOptions)}
                    fullWidth
                    sx={styles.selectInput}
                  />
                </Box>
              </Grid>
              <Grid size={{ xs: 12, md: 4 }} sx={styles.buttonContainer}>
                <Button data-testid="estateAdFlowSelectSubmitButton" variant="standard" type="submit">
                  {t('forms.estateAd.flowSelector.button')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Form>
    </Formik>
  );
};

EstateAdFlowSelector.displayName = 'EstateAd';
