import { alpha, SxProps } from '@mui/material';
import type { Theme } from '@portals/core/src/themes/themes';

export const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  return {
    paper: {
      width: { xs: '100%', md: '704px', lg: '848px' },
      boxShadow: `0px 2px 3px ${alpha(theme.palette.grey['main'], 0.1)}`,
      borderRadius: 1,
      px: 6,
      pt: 6,
      pb: 5,
    },
    box: {
      maxWidth: { xs: '200px', md: '100%' },
      margin: { xs: '0 auto', md: '0 0 0 18px' },
    },
    labelContainer: {
      display: 'grid',
      alignContent: 'center',
      paddingLeft: 5,
      textAlign: { xs: 'center', md: 'left' },
    },
    label: { color: theme.palette.grey['800'] },
    selectInputContainer: {
      marginTop: { xs: '-8px', lg: 'auto' },
    },
    selectInput: {
      fontSize: { xs: 13, lg: 16 },
      '&& .MuiSelect-select.MuiInput-input': {
        paddingX: {
          xs: theme.spacing(4),
          lg: theme.spacing(5),
        },
      },
      '&& .MuiSelect-select.MuiInput-input:focus': { background: 'transparent' },
      borderTopLeftRadius: '0',
      borderBottomLeftRadius: '0',
      '.MuiSelect-icon': {
        padding: 0,
        pointerEvents: 'none',
      },
    },
    buttonContainer: {
      display: 'grid',
      justifyContent: 'center',
      alignContent: 'center',
    },
  };
};
